<template>
  <div
    id="register"
    style="min-height: calc(100vh - 20px)"
    class="register-page"
    tag="section"
  >
    <b-row>
        <div class="register-box ">
          <div class="register-wrapper">
            <img
             src="@/assets/images/logo-icon.png"
              id="logo_image_r"
            />
          
            <b-form id="register-input-group-1" @submit.prevent="handleSubmit">
              <div class="form-row">
                <div class="col">
                  <b-form-group>
                    <b-form-input
                      id="txt-firstname"
                      type="text"
                      placeholder="First Name "
                      class="mb-2"
                      v-model="$v.form.firstname.$model"
                      :state="validateState('firstname')"
                    ></b-form-input>
                    <b-form-invalid-feedback id="txt-firstname">
                      Please enter first name
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>
                <div class="col">
                  <b-form-group>
                    <b-form-input
                      id="txt-lastname"
                      type="text"
                      placeholder="Last Name "
                      class="mb-2"
                      v-model="$v.form.lastname.$model"
                      :state="validateState('lastname')"
                    ></b-form-input>
                    <b-form-invalid-feedback id="txt-lastname">
                      Please enter last name
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>
              </div>
              <div class="form-row">
                <div class="col">
                  <b-form-group>
                    <b-form-input
                      id="txt-username"
                      type="text"
                      placeholder="User Name "
                      class="mb-2"
                      v-model="$v.form.username.$model"
                      :state="validateState('username')"
                    ></b-form-input>
                    <b-form-invalid-feedback id="txt-username">
                      Please enter user name
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>
                <div class="col">
                  <b-form-group>
                    <b-form-input
                      id="txt-email"
                      type="email"
                      placeholder="User Email "
                      class="mb-2"
                      v-model="$v.form.email.$model"
                      :state="validateState('email')"
                    ></b-form-input>
                    <b-form-invalid-feedback id="txt-email">
                      Please enter email
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>
              </div>

              <div class="form-row">
                <div class="col">
                  <b-form-group>
                    <b-form-input
                      id="txt-contact"
                      type="text"
                      placeholder="contact "
                      class="mb-2"
                      v-model="$v.form.contact.$model"
                      :state="validateState('contact')"
                    ></b-form-input>
                    <b-form-invalid-feedback id="txt-contact">
                      Please enter contact
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>
                <div class="col">
                  <b-form-group>
                    <b-form-input
                      id="txt-password_confirmation"
                      type="password"
                      placeholder="Confirm Password *"
                      class="mb-2"
                      v-model="$v.form.password_confirmation.$model"
                      :state="validateState('password_confirmation')"
                    ></b-form-input>
                    <b-form-invalid-feedback id="txt-pwd">
                      Please enter confirm password
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>
              </div>
              <b-button type="submit" variant="primary" block class="mt-2">
                <i class="fas fa-sign-in-alt"> &nbsp;SIGN IN</i>
              </b-button>

              <div class="mt-3">
                <label>Have an Account?</label>
                <b-link to="/login" class="link">
                  <u>&nbsp;Sign In Now</u>
                </b-link>
              </div>
            </b-form>
            <div
              class="d-flex align-items-center justify-content-center mt-4"
            ></div>
          </div>
        </div>
      <!-- </b-col> -->
    </b-row>
  </div>
</template>

<script>
//import axios from "axios";
import { validationMixin } from "vuelidate";
import { required, minLength, email, sameAs , maxLength , numeric} from "vuelidate/lib/validators";

export default {
  name: "BoxedRegister",
  mixins: [validationMixin],
  data: () => ({
    form: {
      firstname: "",
      lastname: "",
      username: "",
      email: "",
      contact: "",
      password_confirmation: "",
    },
    checkbox: false,
  }),
  computed: {
  },
  validations: {
    form: {
      firstname: {
        required,
      },
      lastname: {
        required,
      },
      username: {
        required,
      },
      email: {
        required,
        email,
      },
      contact: {
        required,
        numeric,
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
      password_confirmation: { required, sameAsPassword: sameAs("password") },
    },
  },
  methods: {
    validateState(field) {
      const { $dirty, $error } = this.$v.form[field];
      return $dirty ? !$error : null;
    },
  
  
  },
};
</script>
<style scoped>
.register-page{
  overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
#logo_image_r
{
    /* width: 50px; */
    /* margin-left: 180px; */
    /* height: 60px; */
    padding-bottom: 15px
}

.register-wrapper
{
  text-align: center;
}
.register-box {
    background: #fff;
    max-width: 650px;
    width: 100%;
    padding: 30px;
    margin: 8% auto;
}
@media screen and (max-width:768px) {
  .register-box {
    max-width: 100%;
    margin: 30px;
}
}
@media screen and (max-width:475px) {

.form-row{
  flex-direction: column;
}
}
</style>